@tailwind base;
@tailwind components;
@tailwind utilities;

header > a {
  @apply p-6;
}

h2 {
  @apply text-3xl font-bold;
}

h3 {
  @apply text-xl font-bold;
}

.column {
  @apply w-full p-4;

  @screen lg {
    @apply w-1/2 p-4;
  }
}

.column-full {
  @apply w-full p-4;
}

h2,
h3,
p,
pre {
  @apply mb-4 block;
}

pre {
  @apply overflow-auto;
}

.button {
  @apply bg-blue-500 text-white font-bold py-2 px-4 border-b-4 border-blue-700 rounded mb-4 inline-block;

  &:hover {
    @apply bg-blue-400 border-blue-500;
  }
}

pre {
  @apply bg-gray-600 rounded-lg;
}

code {
  @apply inline-block m-4;
}

.center {
  @apply overflow-hidden flex justify-center;
}

form {
  @apply bg-gray-900 shadow-md rounded p-4 mb-4;
}

label {
  @apply block text-gray-200 text-sm font-bold mb-2;
}

input {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-600 leading-tight;
  &:focus {
    @apply outline-none ring;
  }
}

button {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
  &:hover {
    @apply bg-blue-700 outline-none ring;
  }
}

.card {
  @apply flex-auto flex max-w-sm rounded overflow-hidden shadow-xl flex-col bg-gray-600 mr-4 mb-4;

  img {
    @apply object-contain object-center;
    width: 384px !important;
    height: 216px !important;
  }

  h3 {
    @apply font-bold text-xl mb-2 inline-block px-3 py-1 mr-2;
    text-overflow: ellipsis;
  }
}

.media {
  @apply rounded shadow-2xl mb-4;

  max-height: 60vh;
}

.player {
  @apply w-full bg-black;
}

.image {
  @apply object-contain object-center bg-gray-800;
}

.uppy-Dashboard-inner {
  @apply border-gray-600 !important;
}

#upload-element {
  height: 550px
}

small {
  @apply text-sm;

  &:not(:first-child) {
    @apply ml-1;

    ::before {
      content: ' - ';
      @apply mr-1;
    }
  }

  a:hover {
    @apply text-blue-200 shadow-md;
  }
}